import React from "react";
import Layout from "../../components/layout-2";
import SEO from "../../components/seo";
import { Box, Flex } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import {
  HumanNutrition,
  AnimalNutrition,
  Food,
  PersonalCare,
  IndustrialUse,
} from "../../components/industries";

const IndustriesPage = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Kaminds Nutrichem Private Limited",
    alternateName: "kaminds",
    url: " https://www.kaminds.com/applications/",
    logo:
      "https://www.kaminds.com/static/d2284c154057c362c68fe045665929d5/63311/Trademark%20Kaminds_RGB%20Logo_Logo_Trademark.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+917568015258",
      contactType: "technical support",
      areaServed: "IN",
      availableLanguage: ["en", "Hindi"],
    },
    sameAs: [
      "https://www.linkedin.com/company/kaminds",
      " https://www.kaminds.com/applications/ ",
    ],
  };

  return (
    <Layout>
      <SEO
        title="Applications"
        description="Global supplier of Chemicals &amp; Nutraceutical ingredients for Human Nutrition, Animal Nutrition and Personal Care."
        schemaMarkup={schemaMarkup}
        keywords="Product Application, Kaminds, Food, Nutraceutical, Animal Nutrition, Personal Care"
        links={[
          { rel: "canonical", href: "https://www.kaminds.com/applications/" },
        ]}
      />
      <Box position="relative" background="black">
        <Box
          position="absolute"
          top={["37%", "43%", "50%", "50%", "56%"]}
          left={["55%", "47%", "47%", "47%", "47%"]}
          transform="translate(-53%, -50%)"
          // top={["27%", "27%", "10%", "20%", "27%"]}
          // left={["7%", "10%", "20%", "20%", "20%"]}
          height={["40%", "40%", "50%", "50%", "50%"]}
          width={["90%", "80%", "80%", "65%"]}
          zIndex="1"
        >
          <Flex
            direction="column"
            justifyContent="space-between"
            height="100%"
            width={["95%", "100%", "90%", "90%", "90%"]}
          >
            <Box>
              <Box
                as="h1"
                fontSize={[
                  "58px",
                  "60px",
                  "85px",
                  "100px",
                  "115px",
                  "125px",
                  // "120px",
                ]}
                fontWeight="400"
                color="#fff"
                width="100%"
                fontFamily="Fjalla One !important"
                lineHeight={["75px", "65px", "90px", "105px", "120px", "135px"]}
                paddingLeft="10px"
              >
                APPLICATIONS
              </Box>
              <Box
                as="p"
                color="#fff"
                fontSize={["16px", "17px", "20px", "22px", "26px", "27px"]}
                paddingLeft="10px"
                marginTop={["25px", "25px", "30px", "35px", "42px", "45px"]}
                marginBottom="20px"
                // lineHeight={["25px", "33px", "33px", "33px", "33px"]}
              >
                From enriching the nutritional and taste profiles of food and
                beverages to delivering tailored solutions for nutraceutical and
                animal nutrition, our formulation development expertise and
                in-depth knowledge of ingredients are what sets us apart as the
                most comprehensive.
              </Box>
            </Box>
          </Flex>
        </Box>
        <Box
          display={["block", "none", "none", "none", "none"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../../images/applications-small.jpg"
            // width={800}
            height={900}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.7" }}
            // style={{ width: "100%" }}
          />
        </Box>
        <Box
          display={["none", "block", "none", "none", "none"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../../images/applications-medium.jpg"
            width={800}
            height={600}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.7" }}
            // style={{ width: "100%" }}
          />
        </Box>
        <Box
          display={["none", "none", "block", "none", "none"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../../images/applications.jpg"
            width={1920}
            height={1100}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.7" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "block", "none"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../../images/applications.jpg"
            width={1920}
            height={1100}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.7" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "none", "block"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../../images/applications.jpg"
            width={1920}
            height={800}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.7" }}
          />
        </Box>
      </Box>
      <Box
        padding={["2em 1.2em 3em", "2em 2em 3em"]}
        marginTop={["40px", "40px", "80px", "60px", "60px"]}
        marginBottom={["40px", "40px", "80px", "60px", "60px"]}
      >
        <Box maxWidth="1700" margin="auto">
          {/* <Box
            color="#fff"
            textAlign="center"
            color="#252869"
            // fontFamily="Roboto Condensed !important"
            fontSize="45px"
            fontWeight="400"
          >
            Industries We Serve
          </Box>
          <Box
            height="4px"
            width="85px"
            background="#d61215"
            margin="5px auto 25px"
          ></Box> */}
          <Flex
            justifyContent={[
              "space-around",
              "space-around",
              "space-around",
              "space-around",
              "space-between",
            ]}
            paddingTop="30px"
            wrap="wrap"
            // direction={["column", "column", "column", "row"]}
          >
            <HumanNutrition />
            <AnimalNutrition />
            <Food />
            <PersonalCare />
            <IndustrialUse />
          </Flex>
        </Box>
      </Box>
    </Layout>
  );
};

export default IndustriesPage;
